import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../logo.svg';
import Editor from 'react-simple-code-editor';

const HomeScreenView = ({
    warnings,
    code,
    setCode,
    highlight,
    styles,
    textareaHeight,
    textareaWidth,
    functions,
    variables,
}) => {
    return (
        <AppContainer>
            <EditorContainer
                textareaHeight={textareaHeight}
                textareaWidth={textareaWidth}
            >
                <Editor
                    value={code}
                    onValueChange={(value) => setCode(value)}
                    highlight={highlight}
                    padding={10}
                    style={styles.root}
                    className="simple-editor"
                />
            </EditorContainer>
            <WarningContainer>
                <WarningTitle>ANALYTICS</WarningTitle>
                <div className="px-5 text-[#7d8b99] text-sm flex flex-col gap-2">
                    {variables.length > 0 ? (
                        <p>
                            {variables.length}{' '}
                            {variables.length === 1 ? 'variable' : 'variables'}{' '}
                            set:{' '}
                            {variables.map((variable, i) => (
                                <span key={i}>
                                    {variable}
                                    {variables.length === i + 1 ? '' : ', '}
                                </span>
                            ))}
                        </p>
                    ) : null}

                    {functions.length > 0 ? (
                        <p>
                            {functions.length}{' '}
                            {functions.length === 1 ? 'function' : 'functions'}{' '}
                            used:{' '}
                            {functions.map((func, i) => (
                                <span key={i}>
                                    {func}()
                                    {functions.length === i + 1 ? '' : ', '}
                                </span>
                            ))}
                        </p>
                    ) : null}

                    {functions.length === 0 && variables.length === 0 ? (
                        <span>No variables or functions found</span>
                    ) : null}
                </div>

                <WarningTitle>WARNINGS</WarningTitle>
                {warnings.length > 0 ? (
                    warnings.map((warning, i) =>
                        warning.type === 'functionParams' ? (
                            <Warning key={i}>
                                <span>
                                    Invalid number of arguments passed in the{' '}
                                </span>{' '}
                                <a
                                    style={{ color: '#7d8b99' }}
                                    href={warning.link}
                                    target="_blank"
                                    className="underline"
                                >
                                    {warning.name}
                                </a>{' '}
                                {warning.text}
                                {warning.args ? (
                                    <ol className="px-10 py-5 list-decimal">
                                        {warning.args.map((arg) => (
                                            <li>
                                                {arg.name} -{' '}
                                                {arg.required
                                                    ? 'REQUIRED'
                                                    : 'OPTIONAL'}
                                                {' '}[{arg.type}]  
                                            </li>
                                        ))}
                                    </ol>
                                ) : null}
                            </Warning>
                        ) : warning.type === 'functionParamsUserDefined' ? (
                            <Warning key={i}>
                                <span>
                                    Invalid number of arguments passed in the{' '}
                                </span>{' '}
                                <a
                                    className="underline"
                                    style={{ color: '#7d8b99' }}
                                    href={warning.link}
                                    target="_blank"
                                >
                                    {warning.name}
                                </a>{' '}
                                {warning.text}
                            </Warning>
                        ) : (
                            <Warning key={i}>{warning.text}</Warning>
                        ),
                    )
                ) : (
                    <div
                        className="text-sm"
                        style={{
                            lineHeight: '21.6px',
                            color: '#7d8b99',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                        }}
                    >
                        No problems detected
                    </div>
                )}
            </WarningContainer>
            <ExplanationContainer>
                <div>
                    <StyledLogo />
                </div>

                <ExplanationText>
                    Hello and welcome to <strong>ampscript.io</strong>, the
                    AMPscript syntax validation and highlighting tool. This
                    application enables easy <strong>AMPscript</strong> editing
                    through syntax highlighting and it will warn you about
                    possible syntax errors in your code.
                    <br></br>
                    <br></br>
                    AMPscript function definitions courtesy of{' '}
                    <a
                        className="text-indigo-900 underline highlight"
                        href="https://ampscript.guide/"
                        target="_blank"
                        rel="noopener"
                    >
                        The AMPscript Guide
                    </a>
                    .<br></br>
                    <br></br>
                    To send your feedback, please email{' '}
                    <a
                        className="text-indigo-900 underline highlight"
                        href="mailto:info@ampscript.io"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        info@ampscript.io
                    </a>
                    .
                    <br></br>
                    <br></br>
                    And while you are here, check out my latest project,{' '}
                    <a
                        className="text-indigo-900 underline highlight"
                        href="https://dataviews.io"
                        target="_blank"
                        rel="noopener"
                    >
                        dataviews.io
                        
                    </a>
                </ExplanationText>

                <ExplanationFooter>
                Copyright &copy; 2020-{new Date().getFullYear()}{' '}
                    <a
                        className="text-indigo-900 underline highlight"
                        href="https://sfmarketing.cloud/about/"
                        target="_blank"
                        rel="noopener"
                    >
                        Zuzanna Jarczynska
                    </a>
                    . All Rights Reserved.
                    <a
                        className="text-indigo-900 underline highlight"
                        href="https://twitter.com/ampscript_io"
                        target="_blank"
                        rel="noopener"
                    >
                        <br></br>
                        Twitter
                    </a>{' '}
                    |{' '}
                    <a
                        className="text-indigo-900 underline highlight"
                        href="https://www.youtube.com/channel/UCe0ak9QdbkpsURGVwTatumQ"
                        target="_blank"
                        rel="noopener"
                    >
                        YouTube
                    </a>{' '}
                    |{' '}
                    <a
                        className="text-indigo-900 underline highlight"
                        href="https://www.linkedin.com/in/zuzannajarczynska/"
                        target="_blank"
                        rel="noopener"
                    >
                        Linkedin
                    </a>
                </ExplanationFooter>
            </ExplanationContainer>
        </AppContainer>
    );
};

export default HomeScreenView;

const StyledLogo = styled(Logo)`
    box-sizing: border-box;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 90%;
    margin: auto;
    display: block;
    @media (max-width: 1024px) {
        padding: 20px;
        width: 35%;
        margin: auto;
        display: block;
        margin: auto;
    }
    @media (max-width: 736px) {
        padding: 20px;
        width: 65%;
        margin: auto;
        display: block;
        margin: auto;
    }
`;

const EditorContainer = styled.div`
    resize: horizontal;
    overflow: auto;
    min-width: 55vw;
    height: 100%;
    min-height: 100vh;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 1em;
    text-align: left;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    > div {
        overflow: auto !important;
        white-space: pre;
        height: 100%;
        min-height: 100vh;
        max-height: 100vh;
        ::-webkit-scrollbar-corner {
            background-color: #1e1f1d;
        }
    }
    .module-theme-okaidia .line-numbers {
        outline: none;
    }
    .npm__react-simple-code-editor__textarea {
        padding-left: 42px !important;
    }
    pre {
        height: 100%;
        min-height: 100vh;
        outline: none;
        white-space: pre !important;
        word-wrap: normal !important;
    }
    textarea {
        outline: none;
        white-space: pre !important;
        height: ${({ textareaHeight }) => textareaHeight}px!important;
        width: ${({ textareaWidth }) => textareaWidth}px!important;
        word-wrap: normal !important;
    }
    @media (max-width: 1024px) {
        width: 100%;
        height: auto;
        min-height: 60vh;
    }
`;

const WarningContainer = styled.div`
    font-size: 1em;
    width: 30vw;
    background: #333;
    height: 100vh;
    overflow: auto;
    @media (max-width: 1024px) {
        width: 100%;
        height: auto;
        padding-top: 10px;
        padding-bottom: 20px;
    }
`;

const ExplanationContainer = styled.div`
    position: -webkit-sticky; /* Safari */
    position: sticky;
    height: 100vh;
    box-sizing: border-box;
    top: 0;
    width: 15vw;
    min-width: 200px;
    background: #009edb;
    @media (max-width: 1024px) {
        min-height: auto;
        position: relative;
        width: 100%;
        height: auto;
    }
`;

const AppContainer = styled.div`
    align-item: stretch;
    width: 100vw;
    background: #1e1e1e;
    display: flex;
    flex-direction: row;
    @media (max-width: 1024px) {
        flex-direction: column-reverse;
    }
`;

const ExplanationText = styled.p`
    padding: 20px;
    font-size: 0.9em;
    line-height: 1.5;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    padding-top: 5px;
    padding-bottom: 50px;
`;

const ExplanationFooter = styled.p`
    padding: 20px;
    font-size: 0.6em;
    line-height: 1.5;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    position: absolute;
    bottom: 0;
    padding-bottom: 5px;
    margin: auto;
    text-align: center;
    @media (max-width: 1024px) {
        padding: 20px;
        text-align: left;
    }
`;

const WarningTitle = styled.p`
    font-size: 14px;
    line-height: 21px;
    color: #7d8b99;
    font-weight: 700;
    margin: 20px 5px 20px 20px;
`;

const Warning = styled.p`
    font-size: 14px;
    line-height: 21px;
    color: #7d8b99;
    margin: 10px 20px 20px 20px;
`;