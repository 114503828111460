export default[
  {
    "functionName": "HTTPPost2",
    "link": "https://ampscript.guide/httppost2",
    "description": "Similar to the HTTPPost function, this posts content to a defined, publicly accessible URL, but it also provides exception handling if an error is returned from the request.",
    "args":[{
      "name":"URL endpoint used to post content",
      "type":"string",
      "required": true
    },{
      "name" : "Content-Type header value",
      "type":"string",
      "required": true
    },{
      "name" : "Content to include in POST request",
      "type":"string",
      "required": true
    },{
      "name" : "Whether the process continues on an error. A value of true returns an exception.",
      "type":"string",
      "required": false
    },{
      "name" : "Variable used to return status response string",
      "type":"string",
      "required": false
    },{
      "name" : "Variable used to return response row set",
      "type":"string",
      "required": false
    },{
      "name" : "Name of additional header to include in POST request",
      "type":"string",
      "required": false
    },{
      "name" : "Value of additional header to include in POST request",
      "type":"string",
      "required": false
    },{
      "name" : "Name of additional header to include in POST request",
      "type":"string",
      "required": false
  },{
      "name" : "Value of additional header to include in POST request ",
      "type":"string",
      "required": false
  }]
  },
  {
    "functionName": "HTTPPost",
    "link": "https://ampscript.guide/httppost",
    "description": "This function posts content to a defined, publicly accessible URL.",
    "args":[{
      "name":"URL endpoint used to post content",
      "type":"string",
      "required": true
    },{
      "name" : "Content-Type header value",
      "type":"string",
      "required": true
    },{
      "name" : "Content to include in POST request",
      "type":"string",
      "required": true
    },{
      "name" : "Variable used to return response",
      "type":"string",
      "required": false
    },{
      "name" : "Name of additional header to include in POST request",
      "type":"string",
      "required": false
    },{
      "name" : "Value of additional header to include in POST request",
      "type":"string",
      "required": false
    },{
      "name" : "Name of additional header to include in POST request",
      "type":"strig",
      "required": false
    },{
      "name" : "Value of additional header to include in POST request",
      "type":"string",
      "required": false
    }]
  },  
{
  "functionName": "Add",
  "link": "https://ampscript.guide/add",
  "description": "This function returns the sum of two numbers.",
  "args":[{
    "name":"First value to add",
    "type":"number",
    "required": true
  },{
    "name" : "Second value to add",
    "type":"number",
    "required": true}]
},
{
  "functionName": "AddMSCRMListMember",
  "link": "https://ampscript.guide/addmscrmlistmember",
  "description": "This function adds a member to the specified Microsoft Dynamics CRM list. It does not return a value.",
  "args":[{
    "name":"The GUID of the member to add",
    "type":"string",
    "required": true
  },{
    "name" : "The GUID of the list receiving the member",
    "type":"string",
    "required": true}]
},
{
  "functionName": "AddObjectArrayItem",
  "link": "https://ampscript.guide/addobjectarrayitem",
  "description": "This function adds an object to a Marketing Cloud API Object array.",
  "args":[{
    "name":"The API Object that contains the array",
    "type":"object",
    "required": true
  },{
    "name" : "The array property associated with the item to be added",
    "type":"string",
    "required": true
  },{
    "name" : "The item to add to the array",
    "type":"string",
    "required": true}]
},
{
  "functionName": "AttachFile",
  "link": "https://ampscript.guide/attachfile",
  "description": "This function attaches the specified file to the outbound message or displays a link to the file when included in a landing page. This function returns no output.",
  "args":[{
    "name":"Type of location containing the file to attach",
    "type":"string",
    "required": true
  },{
    "name" : "Path or key of the asset to attach",
    "type":"string",
    "required": true
  },{
    "name" : "Alias assigned to file when attached",
    "type":"string",
    "required": false
  },{
    "name" : "Show link to file in the View as a Web Page context",
    "type":"boolean",
    "required": false
  },{
    "name" : "URL of the attachment to use in the View as a Web Page context",
    "type":"string",
    "required": false
  },{
    "name" : "Link name for file in the View as a Web Page context",
    "type":"string",
    "required": false
  },{
    "name" : "Number of days the link to download the file appears in the View as a Web Page context",
    "type":"number",
    "required": false
  },{
    "name" : "When true, changes the content-disposition from inline to attachment, when the default is inline",
    "type":"boolean",
    "required": false}]
},
{
  "functionName": "AttributeValue",
  "link": "https://ampscript.guide/attributevalue",
  "description": "This function returns the value of an attribute based on the context of the Contact or Subscriber. These values are derived from a field or attribute related to the Contact or Subscriber that the message is sent to.",
  "args":[{
    "name":"The attribute name",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "BarcodeURL",
  "link": "https://ampscript.guide/barcodeurl",
  "description": "This function generates a barcode image URL using the specified arguments.",
  "args":[{
    "name":"Value to convert into a barcode",
    "type":"string",
    "required": true
  },{
    "name" : "Type of barcode to generate",
    "type":"string",
    "required": true
  },{
    "name" : "Barcode image width in pixels",
    "type":"number",
    "required": true
  },{
    "name" : "Barcode image height in pixels",
    "type":"number",
    "required": true
  },{
    "name" : "Checksum value for barcode",
    "type":"string",
    "required": false
  },{
    "name" : "Display value from Ordinal 1 under barcode",
    "type":"boolean",
    "required": false
  },{
    "name" : "Alternate text to display under the barcode if Ordinal 6 is false",
    "type":"string",
    "required": false
  },{
    "name" : "Orientation of barcode in degrees. Valid values are 0, 90, 180 and 270",
    "type":"number",
    "required": true
  },{
    "name" : "Use a transparent background color",
    "type":"boolean",
    "required": true}]
},
{
  "functionName": "Base64Decode",
  "link": "https://ampscript.guide/base64decode",
  "description": "This function decodes a Base64 encoded string.",
  "args":[{
    "name":"String value to decode",
    "type":"string",
    "required": true
  },{
    "name" : "Optional .NET-supported character-encoding type to utilize in decoding",
    "type":"string",
    "required": false
  },{
    "name" : "When utilized in an email message, a value of 1 will abort the send if the specified string cannot be successfully decoded. A value of 0 will allow the send to continue. The default value is 1.",
    "type":"number",
    "required": false}]
},
{
  "functionName": "Base64Encode",
  "link": "https://ampscript.guide/base64encode",
  "description": "This function Base64 encodes a string.",
  "args":[{
    "name":"String value to encode",
    "type":"string",
    "required": true
  },{
    "name" : "Optional .NET character-encoding type to utilize in encoding. Valid values are ASCII, UTF-7, UTF-8, UTF-16, and UTF-32.",
    "type":"string",
    "required": false}]
},
{
  "functionName": "BeginImpressionRegion",
  "link": "https://ampscript.guide/beginimpressionregion",
  "description": "This function marks the beginning of a region to be tracked within the impression tracking infrastructure. It has no output.",
  "args":[{
    "name":"Name of the Impression Region to track",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "BuildRowSetFromString",
  "link": "https://ampscript.guide/buildrowsetfromstring",
  "description": "This function returns a row set by splitting a string by the specified delimiter string.",
  "args":[{
    "name":"String to split into a row set",
    "type":"string",
    "required": true
  },{
    "name" : "Delimiting string",
    "type":"string",
    "required": true}]
},
{
  "functionName": "BuildRowSetFromJSON",
  "link": "https://ampscript.guide/BuildRowSetFromJSON",
  "description": "This function creates a row set from an JSON string. It returns an error or an empty row set if there no matches the specified JSON path.",
  "args":[{
    "name":"JSON string to parse into a row set",
    "type":"string",
    "required": true
  },{
    "name" : "JSON path selector",
    "type":"string",
    "required": true
  },{
    "name" : "When no match is found, a value of 0 returns an error, while a 1 returns an empty row set",
    "type":"boolean",
    "required": true}]
},
{
  "functionName": "BuildRowSetFromXML",
  "link": "https://ampscript.guide/buildrowsetfromxml",
  "description": "This function creates a row set from an XML string. It returns an error or an empty row set if no XML matches the specified XPATH.",
  "args":[{
    "name":"Source XML string to parse",
    "type":"string",
    "required": true
  },{
    "name" : "XPATH string that specifies the root XML node from which to build the row set",
    "type":"string",
    "required": true
  },{
    "name" : "Return an empty row set if XPATH is not found in source string",
    "type":"boolean",
    "required": true}]
},
{
  "functionName": "Char",
  "link": "https://ampscript.guide/char",
  "description": "This function returns the ASCII character for the specified ASCII character code.",
  "args":[{
    "name":"Character code",
    "type":"number",
    "required": true
  },{
    "name" : "Number of times to repeat the ASCII character returned",
    "type":"number",
    "required": false}]
},
{
  "functionName": "ContentArea",
  "link": "https://ampscript.guide/contentarea",
  "description": "This function returns the content stored in the specified Content Area and optionally wraps the content in an Impression Region.",
  "args":[{
    "name":"The ID of the Content Area to return. This can be specified as a String or Number.",
    "type":"number",
    "required": true
  },{
    "name" : "Name of the Impression Region to associate with the Content Area",
    "type":"string",
    "required": false
  },{
    "name" : "Return an error if the Content Area cannot be found",
    "type":"boolean",
    "required": false
  },{
    "name" : "Default content to return if an error occurs from retrieving the Content Area specified in Ordinal 1",
    "type":"string",
    "required": false
  },{
    "name" : "Numeric status code resulting from the retrieve. A value of 0 indicates the retrieve was successful, while -1 indicates that the content was not found or empty.",
    "type":"number",
    "required": false}]
},
{
  "functionName": "ContentAreaByName",
  "link": "https://ampscript.guide/contentareabyname",
  "description": "This function returns the content stored in the specified Content Area and optionally wraps the content in an Impression Region.",
  "args":[{
    "name":"The full path of the Content Area to return",
    "type":"string",
    "required": true
  },{
    "name" : "Name of the Impression Region to associate with this Content Area",
    "type":"string",
    "required": false
  },{
    "name" : "Return an error if the Content Area cannot be found",
    "type":"boolean",
    "required": false
  },{
    "name" : "Default content if an error occurs retrieving the Content Area specified in Ordinal 1",
    "type":"string",
    "required": false
  },{
    "name" : "Numeric status code resulting from the retrieve. A value of 0 indicates the retrieve was successful, while -1 indicates that the content was not found or empty.",
    "type":"number",
    "required": false}]
},
{
  "functionName": "ContentBlockByID",
  "link": "https://ampscript.guide/contentblockbyid",
  "description": "This function returns the content stored in the specified Content Block and optionally wraps the content in an Impression Region.",
  "args":[{
    "name":"The ID of the content block to return. This can be specified as a String or Number",
    "type":"number",
    "required": true
  },{
    "name" : "Name of the Impression Region to associate with this Content Area",
    "type":"string",
    "required": false
  },{
    "name" : "Return an error if the Content Area cannot be found",
    "type":"boolean",
    "required": false
  },{
    "name" : "Default content if an error occurs retrieving the Content Area specified in Ordinal 1",
    "type":"string",
    "required": false
  },{
    "name" : "Numeric status code resulting from the retrieve. A value of 0 indicates the retrieve was successful, while -1 indicates that the content was not found or empty.",
    "type":"number",
    "required": false}]
},
{
  "functionName": "ContentBlockByKey",
  "link": "https://ampscript.guide/contentblockbykey",
  "description": "This function returns the content stored in the specified Content Block and optionally wraps the content in an Impression Region.",
  "args":[{
    "name":"The Customer Key of the content block to return.",
    "type":"string",
    "required": true
  },{
    "name" : "Name of the Impression Region to associate with this Content Area",
    "type":"string",
    "required": false
  },{
    "name" : "Return an error if the Content Area cannot be found",
    "type":"boolean",
    "required": false
  },{
    "name" : "Default content if an error occurs retrieving the Content Area specified in Ordinal 1",
    "type":"string",
    "required": false
  },{
    "name" : "Numeric status code resulting from the retrieve. A value of 0 indicates the retrieve was successful, while -1 indicates that the content was not found or empty.",
    "type":"number",
    "required": false}]
},
{
  "functionName": "ContentBlockByName",
  "link": "https://ampscript.guide/contentblockbyname",
  "description": "This function returns the content stored in the specified Content Block and optionally wraps the content in an Impression Region.",
  "args":[{
    "name":"The full path of the content block to return",
    "type":"string",
    "required": true
  },{
    "name" : "Name of the Impression Region to associate with this Content Area",
    "type":"string",
    "required": false
  },{
    "name" : "Return an error if the Content Area cannot be found",
    "type":"boolean",
    "required": false
  },{
    "name" : "Default content if an error occurs retrieving the Content Area specified in Ordinal 1",
    "type":"string",
    "required": false
  },{
    "name" : "Numeric status code resulting from the retrieve. A value of 0 indicates the retrieve was successful, while -1 indicates that the content was not found or empty.",
    "type":"number",
    "required": false}]
},
{
  "functionName": "ContentImageByID",
  "link": "https://ampscript.guide/contentimagebyid",
  "description": "This function returns an HTML img tag with the specified Content Builder image. It also sets the title, alt, border and thid attributes on the img tag. The title and alt values are set to the name of the image as defined in Content Builder. The border is set to 0 in all circumstances. The thid is a proprietary Marketing Cloud attribute that contains the ID of the specified image in Content Builder.",
  "args":[{
    "name":"ID value of the image to return",
    "type":"string",
    "required": true
  },{
    "name" : "ID value of the image to return if the image for the specified ID is not found",
    "type":"string",
    "required": false}]
},
{
  "functionName": "ContentImageByKey",
  "link": "https://ampscript.guide/contentimagebykey",
  "description": "This function returns an HTML img tag with the specified Content Builder image. It also sets the title, alt, border and thid attributes on the img tag. The title and alt values are set to the name of the image as defined in Content Builder. The border is set to 0 in all circumstances. The thid is a proprietary Marketing Cloud attribute that contains the ID of the specified image in Content Builder.",
  "args":[{
    "name":"Customer/External Key value of the image to return",
    "type":"string",
    "required": true
  },{
    "name" : "Customer/External Key value of the image to return if the image for the specified Customer/External Key is not found",
    "type":"string",
    "required": true}]
},
{
  "functionName": "CreateObject",
  "link": "https://ampscript.guide/createobject",
  "description": "This function instantiates the specified Marketing Cloud API Object.",
  "args":[{
    "name":"The name of the API Object to instantiate",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "CreateSmsConversation",
  "link": "https://ampscript.guide/createsmsconversation",
  "description": "This function provides the ability to conditionally create a conversation and can dynamically define the Next Keyword to use, instead of a defined value in the message template.",
  "args":[{
    "name":"Short code or long code used by MobileConnect",
    "type":"number",
    "required": true
  },{
    "name" : "Contact’s mobile number with country code prefix",
    "type":"number",
    "required": true
  },{
    "name" : "Keyword to use for Next Keyword function",
    "type":"string",
    "required": true
  },{
    "name" : "Application used for the conversation (use MOBILECONNECT)",
    "type":"string",
    "required": true}]
},
{
  "functionName": "DataExtensionRowCount",
  "link": "https://ampscript.guide/dataextensionrowcount",
  "description": "This function returns the number of rows in the specified Data Extension.",
  "args":[{
    "name":"Data Extension from which to retrieve a row count",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "DateAdd",
  "link": "https://ampscript.guide/dateadd",
  "description": "This function returns a date with the specified number interval added to the specified part of the date.",
  "args":[{
    "name":"The date to adjust",
    "type":"date",
    "required": true
  },{
    "name" : "The interval to add",
    "type":"number",
    "required": true
  },{
    "name" : "The part of the date to adjust by the preceding interval. Valid values include Y, M, D, H, and MI",
    "type":"string",
    "required": true}]
},
{
  "functionName": "DateDiff",
  "link": "https://ampscript.guide/datediff",
  "description": "This function returns the difference between two dates based on the specified date part.",
  "args":[{
    "name":"The start date from which to subtract",
    "type":"date",
    "required": true
  },{
    "name" : "The date to subtract from the start date",
    "type":"date",
    "required": true
  },{
    "name" : "The part of the date to adjust. Valid values include Y, M, D, H, and MI",
    "type":"string",
    "required": true}]
},
{
  "functionName": "DateParse",
  "link": "https://ampscript.guide/dateparse",
  "description": "This function converts the string representation of a date and time into its DateTime equivalent.",
  "args":[{
    "name":"The date string to parse",
    "type":"date",
    "required": true
  },{
    "name" : "A true value returns the date in UTC format",
    "type":"boolean",
    "required": false}]
},
{
  "functionName": "DatePart",
  "link": "https://ampscript.guide/datepart",
  "description": "This function returns the specified part of a date string.",
  "args":[{
    "name":"The date string from which to extract a part",
    "type":"string",
    "required": true
  },{
    "name" : "The part of the date to retrieve. Valid values include year, Y, month, M, monthName, day, D, hour, H, minute and MI",
    "type":"string",
    "required": true}]
},
{
  "functionName": "DecryptSymmetric",
  "link": "https://ampscript.guide/decryptsymmetric",
  "description": "This function decrypts a string with the specified algorithm and qualifiers.",
  "args":[{
    "name":"String to decrypt",
    "type":"string",
    "required": true
  },{
    "name" : "Algorithm used to decrypt the string. Valid values are aes, des, and tripledes.",
    "type":"string",
    "required": true
  },{
    "name" : "Password External Key for retrieval from Key Management",
    "type":"string",
    "required": true
  },{
    "name" : "Password value",
    "type":"string",
    "required": true
  },{
    "name" : "Salt External Key for retrieval from Key Management",
    "type":"string",
    "required": true
  },{
    "name" : "Salt value as an 8-byte hex string",
    "type":"string",
    "required": true
  },{
    "name" : "Initialization vector External Key for retrieval from Key Management",
    "type":"string",
    "required": true
  },{
    "name" : "Initialization vector value as a 16-byte hex string",
    "type":"string",
    "required": true}]
},
{
  "functionName": "DescribeMSCRMEntityAttributes",
  "link": "https://ampscript.guide/describemscrmentityattributes",
  "description": "This function returns the logical name, display name and type of the specified Microsoft Dynamics CRM entity. If the attribute is Boolean, status, a picklist or a state, the function returns a comma-separated list of option and display values.",
  "args":[{
    "name":"The entity from which to retrieve attributes",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "Divide",
  "link": "https://ampscript.guide/divide",
  "description": "This function returns the result (quotient) of dividing the first parameter by the second parameter.",
  "args":[{
    "name":"Value to be divided (dividend)",
    "type":"number",
    "required": true
  },{
    "name" : "Value to divide by (divisor)",
    "type":"number",
    "required": true}]
},
{
  "functionName": "Domain",
  "link": "https://ampscript.guide/domain",
  "description": "This function returns the domain of an email address.",
  "args":[{
    "name":"The email address",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "Empty",
  "link": "https://ampscript.guide/empty",
  "description": "This function provides a conditional evaluation of an expression. If the expression evaluates as null or empty, the function will output true, otherwise it will output false.",
  "args":[{
    "name":"An attribute, variable or nested function to evaluate",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "EncryptSymmetric",
  "link": "https://ampscript.guide/encryptsymmetric",
  "description": "This function encrypts a string with the specified algorithm and qualifiers. Outputs a Base64 encoded value.",
  "args":[{
    "name":"String to encrypt",
    "type":"string",
    "required": true
  },{
    "name" : "Algorithm used to encrypt the string. Valid values are aes, des, and tripledes.",
    "type":"string",
    "required": true
  },{
    "name" : "Password External Key for retrieval from Key Management",
    "type":"string",
    "required": true
  },{
    "name" : "Password value",
    "type":"string",
    "required": true
  },{
    "name" : "Salt External Key for retrieval from Key Management",
    "type":"string",
    "required": true
  },{
    "name" : "Salt value as an 8-byte hex string",
    "type":"string",
    "required": true
  },{
    "name" : "Initialization vector External Key for retrieval from Key Management",
    "type":"string",
    "required": true
  },{
    "name" : "Initialization vector value as a 16-byte hex string",
    "type":"string",
    "required": true}]
},
{
  "functionName": "EndImpressionRegion",
  "link": "https://ampscript.guide/endimpressionregion",
  "description": "This function marks the end of a previously defined Impression Region set with the BeginImpressionRegion function. It has no output.",
  "args":[{
    "name":"Scope of regions closed by the function. A value of true closes all previous Impression Regions. A value of false closes only the preceding Impression Region. The default value is false.",
    "type":"boolean",
    "required": false
  }]
},
{
  "functionName": "EndSmsConversation",
  "link": "https://ampscript.guide/endsmsconversation",
  "description": "This function will end an active conversation-based on a given short or long code and a Contact’s mobile number. Once a conversation ends, it will allow the Contact to send other MO messages related to the short or long code, otherwise the Contact will remain in the conversation until it ends, either by reaching a message template without a Next Keyword, or being ejected from the conversation by this function.",
  "args":[{
    "name":"Short code or long code used by MobileConnect",
    "type":"number",
    "required": true
  },{
    "name" : "Contact’s mobile number with country code prefix",
    "type":"number",
    "required": true}]
},
{
  "functionName": "ExecuteFilter",
  "link": "https://ampscript.guide/executefilter",
  "description": "This function executes the specified Data Extension Data Filter and returns a row set. This function only works in a landing page, microsite page or CloudPage.",
  "args":[{
    "name":"Customer/External Key of the Data Filter to execute",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "ExecuteFilterOrderedRows",
  "link": "https://ampscript.guide/executefilterorderedrows",
  "description": "This function executes the specified Data Extension Data Filter and returns a row set with the specified number of rows. Rows are returned sorted by the columns specified in the third parameter.",
  "args":[{
    "name":"Customer/External Key of the Data Filter to execute",
    "type":"string",
    "required": true
  },{
    "name" : "Number of rows to return. A value of 0 returns all rows. No maximum limit on rows returned.",
    "type":"number",
    "required": true
  },{
    "name" : "Name of column used to sort resulting row set. Add a ASC or DESC suffix to specify the sort order. ASC is the default. Specify multiple sort columns by separating them with a comma.",
    "type":"string",
    "required": true}]
},
{
  "functionName": "Field",
  "link": "https://ampscript.guide/field",
  "description": "This function returns the value of a field from a row set or the value of an application programming interface (API) object property.",
  "args":[{
    "name":"Row set or API object from which to return the value or property",
    "type":"string",
    "required": true
  },{
    "name" : "Name or ordinal position of the row set field or API object property to retrieve",
    "type":"string",
    "required": true
  },{
    "name" : "Indicates whether to return an empty string value or an error if the specified field or property does not exist. Specifying 0 will return a null value, while 1 will result in a runtime error. The default value is 1.",
    "type":"boolean",
    "required": false}]
},
{
  "functionName": "Format",
  "link": "https://ampscript.guide/format",
  "description": "This function formats a string in the specified format pattern and locale.",
  "args":[{
    "name":"The string to format",
    "type":"string",
    "required": true
  },{
    "name" : "The output format pattern (see table below for values)",
    "type":"string",
    "required": true
  },{
    "name" : "Data format indicator. Valid values are Date and Number",
    "type":"string",
    "required": false
  },{
    "name" : "The ISO locale code for the output",
    "type":"string",
    "required": false}]
},
{
  "functionName": "FormatCurrency",
  "link": "https://ampscript.guide/formatcurrency",
  "description": "This function formats a string as a currency for the specified locale.",
  "args":[{
    "name":"Currency string to format",
    "type":"string",
    "required": true
  },{
    "name" : "ISO locale code for the currency unit",
    "type":"string",
    "required": true
  },{
    "name" : "Decimal places to include in the output",
    "type":"number",
    "required": false
  },{
    "name" : "Currency symbol for output. This overrides the currency symbol specified by the ISO locale code",
    "type":"string",
    "required": false}]
},
{
  "functionName": "FormatDate",
  "link": "https://ampscript.guide/formatdate",
  "description": "This function formats a date/time string in the specified format pattern and locale.",
  "args":[{
    "name":"The date string to format",
    "type":"string",
    "required": true
  },{
    "name" : "The output date format pattern",
    "type":"string",
    "required": true
  },{
    "name" : "The output time format pattern",
    "type":"string",
    "required": false
  },{
    "name" : "The ISO locale code for the output",
    "type":"string",
    "required": false}]
},
{
  "functionName": "FormatNumber",
  "link": "https://ampscript.guide/formatnumber",
  "description": "This function formats a string as a number.",
  "args":[{
    "name":"The number string to format",
    "type":"string",
    "required": true
  },{
    "name" : "The output format pattern",
    "type":"string",
    "required": true
  },{
    "name" : "ISO code for locale-specific pattern elements",
    "type":"string",
    "required": false}]
},
{
  "functionName": "GetPortfolioItem",
  "link": "https://ampscript.guide/getportfolioitem",
  "description": "This function returns Portfolio item content for the specified Customer/External Key.",
  "args":[{
    "name":"Customer/External Key of the Portfolio item to return",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "GetPublishedSocialContent",
  "link": "https://ampscript.guide/getpublishedsocialcontent",
  "description": "This function returns previous Social Content specified by the Region ID.",
  "args":[{
    "name":"Region ID of the Social Content Area to return",
    "type":"number",
    "required": true
  }]
},
{
  "functionName": "GetSendTime",
  "link": "https://ampscript.guide/getsendtime",
  "description": "This function returns the date and time a send starts or the date and time a send has completed for a specific subscriber. The date and time returned is Central Standard Time (CST) without any adjustments for daylight savings.",
  "args":[{
    "name":"A value of true returns the date and time the send started. A value of false returns the date and time the send completed for a subscriber. The default value is false if no value is specified.",
    "type":"boolean",
    "required": false
  }]
},
{
  "functionName": "GetValue",
  "link": "https://ampscript.guide/getvalue",
  "description": "This function returns the value of the specified Subscriber attribute or AMPscript variable.",
  "args":[{
    "name":"Subscriber attribute or AMPscript variable from which to return a value",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "HTTPGet",
  "link": "https://ampscript.guide/httpget",
  "description": "This function retrieves, or ‘gets’ content from a defined publicly accessible URL.",
  "args":[{
    "name":"URL used to retrieve content",
    "type":"string",
    "required": true
  },{
    "name" : "Whether the process continues on an error. A value of true ignores errors in the process. It defaults to false if not specified.",
    "type":"string",
    "required": false
  },{
    "name" : "Defines whether the function allows empty content. A value of 0 permits empty content. A value of 1 returns an error. A value of 2 skips sending an email to the Subscriber (if the function is used in an email).",
    "type":"string",
    "required": false
  },{
    "name" : "Outputs the function status. A value of 0 indicates the status is successful, -1 indicates a 404 error (URL not found), -2 indicates an HTTP request error (the server did not return a 2xx status code) and -3 indicates empty content.",
    "type":"string",
    "required": false}]
},
{
  "functionName": "HTTPRequestHeader",
  "link": "https://ampscript.guide/httprequestheader",
  "description": "This function returns a specified header from an HTTP request. This function is only available in landing pages, microsites and CloudPages. It cannot be used in other Marketing Cloud applications.",
  "args":[{
    "name":"An HTTP header as defined in RFC 7231",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "IIf",
  "link": "https://ampscript.guide/iif",
  "description": "This in-line IF function returns the value of the second argument if the value for the first argument evaluates as true.",
  "args":[{
    "name":"Conditional expression that returns a true or false",
    "type":"string",
    "required": true
  },{
    "name" : "Value to return of the conditional expression returns true",
    "type":"string",
    "required": true
  },{
    "name" : "Value to return of the conditional expression returns false",
    "type":"string",
    "required": true}]
},
{
  "functionName": "Image",
  "link": "https://ampscript.guide/image",
  "description": "This function returns an HTML img tag with the specified Portfolio image. It also sets the title, alt, border and thid attributes on the img tag. The title and alt values are set to the name of the image as defined in the Portfolio. The border is set to 0 in all circumstances. The thid is a proprietary Marketing Cloud attribute that contains the ID of the specified image in the Portfolio.",
  "args":[{
    "name":"Customer/External Key value of the image to return",
    "type":"string",
    "required": true
  },{
    "name" : "Customer/External Key value of the image to return if the image for the specified Customer/External Key is not found",
    "type":"string",
    "required": true}]
},
{
  "functionName": "IndexOf",
  "link": "https://ampscript.guide/indexof",
  "description": "This function returns the position of the first occurrence of a specified value in a string. The index numbering starts at 1.",
  "args":[{
    "name":"String to search",
    "type":"string",
    "required": true
  },{
    "name" : "String value to find",
    "type":"string",
    "required": true}]
},
{
  "functionName": "InvokeCreate",
  "link": "https://ampscript.guide/invokecreate",
  "description": "This function executes the creation of the instantiated Marketing Cloud API Object. The function returns a status code, message and an error code.",
  "args":[{
    "name":"The API Object to be created",
    "type":"object",
    "required": true
  },{
    "name" : "The AMPscript variable in which the resulting status message is stored",
    "type":"variable",
    "required": true
  },{
    "name" : "The AMPscript variable in which the resulting status message is stored",
    "type":"variable",
    "required": true
  },{
    "name" : "The optional CreateOptions API Object",
    "type":"object",
    "required": false}]
},
{
  "functionName": "InvokeDelete",
  "link": "https://ampscript.guide/invokedelete",
  "description": "This function executes the deletion of the instantiated Marketing Cloud API Object. In order to delete an object, you must specify a ID, ObjectID or CustomerKey property for the object. ",
  "args":[{
    "name":"The API Object to be deleted",
    "type":"object",
    "required": true
  },{
    "name" : "The AMPscript variable in which the resulting status message is stored",
    "type":"variable",
    "required": true
  },{
    "name" : "The AMPscript variable in which the resulting error code is stored",
    "type":"variable",
    "required": true
  },{
    "name" : "The optional DeleteOptions API Object",
    "type":"object",
    "required": false}]
},
{
  "functionName": "InvokeExecute",
  "link": "https://ampscript.guide/invokeexecute",
  "description": "This function executes the instantiated Marketing Cloud API Object. It returns a RequestID value (because it is executed asynchronously), an API Object containing a status message and an error code value.",
  "args":[{
    "name":"The API Object to be executed",
    "type":"object",
    "required": true
  },{
    "name" : "The AMPscript variable in which the resulting status message is stored",
    "type":"string",
    "required": true
  },{
    "name" : "The AMPscript variable in which the resulting RequestID is stored",
    "type":"variable",
    "required": true}]
},
{
  "functionName": "InvokePerform",
  "link": "https://ampscript.guide/invokeperform",
  "description": "This function performs the instantiated Marketing Cloud API Object. It returns a status code value and a status message.",
  "args":[{
    "name":"The API Object to be performed",
    "type":"object",
    "required": true
  },{
    "name" : "The action to perform on the object. Must be a valid action for the object specified in the first argument.",
    "type":"string",
    "required": true
  },{
    "name" : "The AMPscript variable in which the resulting status message is stored",
    "type":"variable",
    "required": false}]
},
{
  "functionName": "InvokeRetrieve",
  "link": "https://ampscript.guide/invokeretrieve",
  "description": "This function retrieves the instantiated Marketing Cloud API Object RetrieveRequest. It returns a status message and a RequestID value.",
  "args":[{
    "name":"The API Object to be retrieved",
    "type":"object",
    "required": true
  },{
    "name" : "The AMPscript variable in which the resulting status message is stored",
    "type":"string",
    "required": false
  },{
    "name" : "The AMPscript variable in which the resulting RequestID is stored",
    "type":"variable",
    "required": false}]
},
{
  "functionName": "InvokeUpdate",
  "link": "https://ampscript.guide/invokeupdate",
  "description": "This function executes an update on the instantiated Marketing Cloud API Object. It returns a status code, message and an error code.",
  "args":[{
    "name":"The API Object to be updated",
    "type":"object",
    "required": true
  },{
    "name" : "The AMPscript variable in which the resulting status message is stored",
    "type":"variable",
    "required": false
  },{
    "name" : "The AMPscript variable in which the resulting error code is stored",
    "type":"variable",
    "required": false
  },{
    "name" : "The optional UpdateOptions API Object",
    "type":"object",
    "required": false}]
},
{
  "functionName": "IsCHTMLBrowser",
  "link": "https://ampscript.guide/ischtmlbrowser",
  "description": "This function checks whether a given user agent value is a C-HTML browser. This function is only available in landing pages, microsites and CloudPages. It cannot be used in other Marketing Cloud applications.",
  "args":[{
    "name":"HTTP user-agent header",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "IsEmailAddress",
  "link": "https://ampscript.guide/isemailaddress",
  "description": "This function confirms whether or not a value matches a valid email syntax. The function returns true if the syntax is valid or false if it’s not.",
  "args":[{
    "name":"Email address to validate",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "IsNull",
  "link": "https://ampscript.guide/isnull",
  "description": "Similar to the Empty function, this function provides a conditional evaluation of an expression. If the expression evaluates as null, the function will output true, otherwise it will output false.",
  "args":[{
    "name":"A variable or nested function to evaluate",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "IsNullDefault",
  "link": "https://ampscript.guide/isnulldefault",
  "description": "This function is used to return a default value for empty Smart Capture form field values.",
  "args":[{
    "name":"Value to return for non-null occurrences",
    "type":"string",
    "required": true
  },{
    "name" : "Value to return for null occurrences",
    "type":"string",
    "required": true}]
},
{
  "functionName": "IsPhoneNumber",
  "link": "https://ampscript.guide/isphonenumber",
  "description": "This function confirms whether or not a value is a valid US phone number. The function returns true if the syntax is valid or false if it’s not. Any non-numeric characters are ignored on evaluation.",
  "args":[{
    "name":"Phone number to validate",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "Length",
  "link": "https://ampscript.guide/length",
  "description": "This function returns the number of characters in the specified string.",
  "args":[{
    "name":"String to measure",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "LiveContentMicrositeURL",
  "link": "https://ampscript.guide/livecontentmicrositeurl",
  "description": "This function enables integration with Live Content in Marketing Cloud.",
  "args":[{
    "name":"Content type; currently only coupon is supported as an argument value",
    "type":"string",
    "required": true
  },{
    "name" : "External Key of the Live Content",
    "type":"string",
    "required": true}]
},
{
  "functionName": "LocalDateToSystemDate",
  "link": "https://ampscript.guide/localdatetosystemdate",
  "description": "This function converts the specified date/time into system time. The date and time returned is Central Standard Time (CST) without any adjustments for daylight savings.",
  "args":[{
    "name":"The date to convert",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "LongSFID",
  "link": "https://ampscript.guide/longsfid",
  "description": "Sales and Service Cloud objects use a case-sensitive 15-digit character as a record identifier. Marketing Cloud Subscriber and Contact records require a case-insensitive identifier. This function converts 15-digit identifiers into 18-digit case-insensitive identifiers.",
  "args":[{
    "name":"15-character Salesforce record identifier",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "Lowercase",
  "link": "https://ampscript.guide/lowercase",
  "description": "This function converts all uppercase letters in the specified string into lowercase letters.",
  "args":[{
    "name":"String to convert into lowercase",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "MD5",
  "link": "https://ampscript.guide/md5",
  "description": "This function converts the specified string into a 16-byte MD5 hash hex value.",
  "args":[{
    "name":"String to convert",
    "type":"string",
    "required": true
  },{
    "name" : "Character set to use for character-encoding. Valid values are UTF-8 (default) and UTF-16",
    "type":"string",
    "required": false}]
},
{
  "functionName": "Mod",
  "link": "https://ampscript.guide/mod",
  "description": "This function returns the remainder after the division of two numbers (also known as a modulo operation).",
  "args":[{
    "name":"Value to be divided (dividend)",
    "type":"number",
    "required": true
  },{
    "name" : "Value to divide by (divisor)",
    "type":"number",
    "required": true}]
},
{
  "functionName": "Multiply",
  "link": "https://ampscript.guide/multiply",
  "description": "This function returns the product of multiplying two numbers.",
  "args":[{
    "name":"Value to be multiplied",
    "type":"number",
    "required": true
  },{
    "name" : "Value to be multiplied",
    "type":"number",
    "required": true}]
},
{
  "functionName": "Now",
  "link": "https://ampscript.guide/now",
  "description": "This function returns the current system date and time or the date and time of the original send. The date and time returned is Central Standard Time (CST) without any adjustments for daylight savings.",
  "args":[{
    "name":"A value of true returns the date and time the send started for a subscriber. A value of false returns the current date and time. The default value is false if no value is specified",
    "type":"boolean",
    "required": false
  }]
},
{
  "functionName": "Output",
  "link": "https://ampscript.guide/output",
  "description": "While code within an AMPscript block does not display output where the block appears in an email on a page, the Output function enables variable values to be output at the location where the code block appears.",
  "args":[{
    "name":"Evaluated function to display",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "OutputLine",
  "link": "https://ampscript.guide/outputline",
  "description": "This function is similar to the Output function, but it inserts a line feed character after the result.",
  "args":[{
    "name":"Evaluated function to display",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "ProperCase",
  "link": "https://ampscript.guide/propercase",
  "description": "This function capitalizes the first letter in the specified string and any other letters in the string that follow any character other than a letter. It converts all other letters into lowercase.",
  "args":[{
    "name":"String to convert into proper case",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "QueryParameter",
  "link": "https://ampscript.guide/queryparameter",
  "description": "This function retrieves a value from a URL query string, based on a defined parameter key.",
  "args":[{
    "name":"URL parameter key",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "RaiseError",
  "link": "https://ampscript.guide/raiseerror",
  "description": "This function enables the suppression or cancellation of an email send. It is useful for exception handling in your code, if an unexpected result occurs.",
  "args":[{
    "name":"Error message to display or log",
    "type":"string",
    "required": true
  },{
    "name" : "Indicates whether the function skips the send for current Subscriber and continues or stops. A value of true skips the send for current Subscriber and moves to next Subscriber. A value of false stops the send and returns an error. The default value is false.",
    "type":"boolean",
    "required": false
  },{
    "name" : "User defined API error code",
    "type":"string",
    "required": false
  },{
    "name" : "User defined API error number",
    "type":"number",
    "required": false
  },{
    "name" : "Indicates whether the function preserves Data Extension rows inserted, updated, or deleted by AMPscript functions before error occurs, even if the process skips the Subscriber. A value of 1 will preserve data operations to Data Extensions before the error is raised, even if the Subscriber is skipped. A value of 0 does not preserve operations before the error.",
    "type":"boolean",
    "required": false}]
},
{
  "functionName": "Random",
  "link": "https://ampscript.guide/random",
  "description": "This function returns a random number between two numbers. Both upper and lower bound parameters are inclusive.",
  "args":[{
    "name":"Value of lower bound",
    "type":"number",
    "required": true
  },{
    "name" : "Value of upper bound",
    "type":"number",
    "required": true}]
},
{
  "functionName": "RatingStars",
  "link": "https://ampscript.guide/ratingstars",
  "description": "This function generates a rating star image, based on a rating attribute of the recommended product.",
  "args":[{
    "name":"The highest ranking to display",
    "type":"number",
    "required": true
  },{
    "name" : "Star color as html hex code or color name",
    "type":"string",
    "required": true
  },{
    "name" : "Size and width of star in pixels",
    "type":"number",
    "required": true}]
},
{
  "functionName": "Redirect",
  "link": "https://ampscript.guide/redirect",
  "description": "This function redirects a landing page to a specified URL.",
  "args":[{
    "name":"The redirect URL",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "RedirectTo",
  "link": "https://ampscript.guide/redirectto",
  "description": "This function is required when using the <a> HTML hyperlink tag in emails when the hypertext reference (href) attribute is either an attribute or a variable and the hyperlink is a tracked email link.",
  "args":[{
    "name":"Attribute or variable containing the URL to redirect to",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "Replace",
  "link": "https://ampscript.guide/replace",
  "description": "This function replaces the first string value with the second string value in the specified string.",
  "args":[{
    "name":"String to search",
    "type":"string",
    "required": true
  },{
    "name" : "String to find",
    "type":"string",
    "required": true
  },{
    "name" : "Replacement string",
    "type":"string",
    "required": true}]
},
{
  "functionName": "RequestParameter",
  "link": "https://ampscript.guide/requestparameter",
  "description": "This function is similar to QueryParameter, but in addition to retrieving a value from a URL query string, it can also be used to retrieve form field values.",
  "args":[{
    "name":"URL parameter key or field name attribute",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "RetrieveMSCRMRecords",
  "link": "https://ampscript.guide/retrievemscrmrecords",
  "description": "This function retrieves rows from the specified Microsoft Dynamics CRM entity.",
  "args":[{
    "name":"The entity from which to retrieve attributes",
    "type":"string",
    "required": true
  },{
    "name" : "Comma-separated list of fields to retrieve",
    "type":"string",
    "required": true
  },{
    "name" : "Field name for filter value",
    "type":"string",
    "required": true
  },{
    "name" : "Operator for filter value",
    "type":"string",
    "required": true
  },{
    "name" : "Value for filter",
    "type":"string",
    "required": true}]
},
{
  "functionName": "RetrieveMSCRMRecordsFetchXML",
  "link": "https://ampscript.guide/retrievemscrmrecordsfetchxml",
  "description": "This function accepts a Fetch XML string and returns the specified Microsoft Dynamics CRM entity attributes.",
  "args":[{
    "name":"Fetch XML string",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "RetrieveSalesforceJobSources",
  "link": "https://ampscript.guide/retrievesalesforcejobsources",
  "description": "This function returns details of a Marketing Cloud send job to a Salesforce audience. ",
  "args":[{
    "name":"JobID of the Salesforce send",
    "type":"number",
    "required": true
  }]
},
{
  "functionName": "Row",
  "link": "https://ampscript.guide/row",
  "description": "This function returns a specific row from a row set.",
  "args":[{
    "name":"Row set from which to return the row",
    "type":"string",
    "required": true
  },{
    "name" : "Position of element to retrieve from the row set",
    "type":"number",
    "required": true}]
},
{
  "functionName": "RowCount",
  "link": "https://ampscript.guide/rowcount",
  "description": "This function returns the number of rows in a row set.",
  "args":[{
    "name":"Row set from which to return the count",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "SetObjectProperty",
  "link": "https://ampscript.guide/setobjectproperty",
  "description": "This function sets a property value for an object created with the CreateObject function.",
  "args":[{
    "name":"The API Object for which the property is set",
    "type":"object",
    "required": true
  },{
    "name" : "The name of the property to set",
    "type":"string",
    "required": true
  },{
    "name" : "The value of the property",
    "type":"string",
    "required": true}]
},
{
  "functionName": "SetSmsConversationNextKeyword",
  "link": "https://ampscript.guide/setsmsconversationnextkeyword",
  "description": "This function will set the keyword for the next message in a conversation. It does not create a new conversation. Rather, it sets the next conversation path similar to CreateSmsConversation, but for an existing conversation. The Next Keyword is set once the Contact replies to the message.",
  "args":[{
    "name":"Short code or long code used by MobileConnect",
    "type":"number",
    "required": true
  },{
    "name" : "Contact’s mobile number with country code prefix",
    "type":"number",
    "required": true
  },{
    "name" : "Keyword to use for Next Keyword function",
    "type":"string",
    "required": true}]
},
{
  "functionName": "SetStateMSCRMRecord",
  "link": "https://ampscript.guide/setstatemscrmrecord",
  "description": "This function sets the state and status of the specified Microsoft Dynamics CRM entity. It does not return a value.",
  "args":[{
    "name":"GUID of the record for which the state and status will be set",
    "type":"string",
    "required": true
  },{
    "name" : "Name of the entity for record",
    "type":"string",
    "required": true
  },{
    "name" : "State value to use. Valid values include Active and Inactive.",
    "type":"string",
    "required": true
  },{
    "name" : "Status value to use. Valid values include 0, 1 and -1. The value of -1 resets to the default value.",
    "type":"string",
    "required": true}]
},
{
  "functionName": "SetValue",
  "link": "https://ampscript.guide/setvalue",
  "description": "This function sets the value of an AMPscript variable inside a block.",
  "args":[{
    "name":"AMPscript variable name",
    "type":"string",
    "required": true
  },{
    "name" : "AMPscript variable value",
    "type":"string",
    "required": true}]
},
{
  "functionName": "SHA1",
  "link": "https://ampscript.guide/sha1",
  "description": "This function converts the specified string into a SHA1 hex value hash.",
  "args":[{
    "name":"String to convert",
    "type":"string",
    "required": true
  },{
    "name" : "Character set to use for character-encoding. Valid values are UTF-8 (default) and UTF-16",
    "type":"string",
    "required": false}]
},
{
  "functionName": "SHA256",
  "link": "https://ampscript.guide/sha256",
  "description": "This function converts the specified string into a SHA256 hex value hash.",
  "args":[{
    "name":"String to convert",
    "type":"string",
    "required": true
  },{
    "name" : "Character set to use for character-encoding. Valid values are UTF-8 (default) and UTF-16",
    "type":"string",
    "required": false}]
},
{
  "functionName": "SHA512",
  "link": "https://ampscript.guide/sha512",
  "description": "This function converts the specified string into a SHA512 hex value hash.",
  "args":[{
    "name":"String to convert",
    "type":"string",
    "required": true
  },{
    "name" : "Character set to use for character-encoding. Valid values are UTF-8 (default) and UTF-16",
    "type":"string",
    "required": false}]
},
{
  "functionName": "StringToDate",
  "link": "https://ampscript.guide/stringtodate",
  "description": "This function parses the specified date string and returns a .NET DateTime object.",
  "args":[{
    "name":"Date string to parse",
    "type":"string",
    "required": true
  },{
    "name" : "Optional .NET character-encoding type to utilize in encoding. Valid values are UTF-8 (default) and UTF-16.",
    "type":"string",
    "required": false}]
},
{
  "functionName": "StringToHex",
  "link": "https://ampscript.guide/stringtohex",
  "description": "This function converts the specified string into its hex equivalent.",
  "args":[{
    "name":"String to convert",
    "type":"string",
    "required": true
  },{
    "name" : "Optional .NET character-encoding type to utilize in conversion. Valid values are UTF-8 (default) and UTF-16.",
    "type":"string",
    "required": false}]
},
{
  "functionName": "Substring",
  "link": "https://ampscript.guide/substring",
  "description": "This function returns a portion of the specified string starting at a certain character position and no longer than the specified length. If the specified character position is more than the length of the specified string, the function returns an empty string. If you do not specify a value for the third parameter, the function will return the remainder of the string.",
  "args":[{
    "name":"The string from which to extract a portion",
    "type":"string",
    "required": true
  },{
    "name" : "Starting position of substring",
    "type":"number",
    "required": true
  },{
    "name" : "Length of substring. If not specified, value defaults to the remaining length of the source string.",
    "type":"number",
    "required": false}]
},
{
  "functionName": "Subtract",
  "link": "https://ampscript.guide/subtract",
  "description": "This function returns the difference between two numbers.",
  "args":[{
    "name":"First value",
    "type":"number",
    "required": true
  },{
    "name" : "Second value",
    "type":"number",
    "required": true}]
},
{
  "functionName": "SystemDateToLocalDate",
  "link": "https://ampscript.guide/systemdatetolocaldate",
  "description": "This function converts the specified system date/time into the local date/time.",
  "args":[{
    "name":"The date to convert",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "TransformXML",
  "link": "https://ampscript.guide/transformxml",
  "description": "This function applies an XSL transformation to the specified XML data.",
  "args":[{
    "name":"XML content to transform",
    "type":"string",
    "required": true
  },{
    "name" : "XSL document used to transform the XML content",
    "type":"string",
    "required": true}]
},
{
  "functionName": "TreatAsContent",
  "link": "https://ampscript.guide/treatascontent",
  "description": "This function forces AMPscript and personalization strings to be evaluated in the specified input string.",
  "args":[{
    "name":"String to evaluate",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "TreatAsContentArea",
  "link": "https://ampscript.guide/treatascontentarea",
  "description": "This function creates a static Content Area that is stored by key for the duration of the send. It will evaluate AMPscript included in the content retrieved, with a limit of 300 unique variations. If there are more than 300 unique variations, any subsequent versions will be rendered as the first.",
  "args":[{
    "name":"Key value that identifies the content specified by the second argument",
    "type":"string",
    "required": true
  },{
    "name" : "Content to be stored",
    "type":"string",
    "required": true
  },{
    "name" : "Name of the Impression Region to wrap around content",
    "type":"string",
    "required": false}]
},
{
  "functionName": "Trim",
  "link": "https://ampscript.guide/trim",
  "description": "This function removes the leading and trailing spaces from the specified string.",
  "args":[{
    "name":"String value to trim",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "UpdateMSCRMRecords",
  "link": "https://ampscript.guide/updatemscrmrecords",
  "description": "This function updates one of more records in the specified Microsoft Dynamics CRM entity. It returns the number of records updated.",
  "args":[{
    "name":"Name of the entity for records",
    "type":"string",
    "required": true
  },{
    "name" : "Comma-separated list of record GUIDs to update",
    "type":"string",
    "required": true
  },{
    "name" : "Name of attribute to update",
    "type":"string",
    "required": true
  },{
    "name" : "Value of attribute to update",
    "type":"string",
    "required": true}]
},
{
  "functionName": "Uppercase",
  "link": "https://ampscript.guide/uppercase",
  "description": "This function converts all lowercase letters in the specified string to uppercase.",
  "args":[{
    "name":"String to convert into uppercase",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "UpsertMSCRMRecord",
  "link": "https://ampscript.guide/upsertmscrmrecord",
  "description": "This function updates one of more records in the specified Microsoft Dynamics CRM entity. It returns the number of records updated.",
  "args":[{
    "name":"Name of the entity for records",
    "type":"string",
    "required": true
  },{
    "name" : "Comma-separated list of record GUIDs to update",
    "type":"string",
    "required": true
  },{
    "name" : "Name of attribute to update",
    "type":"string",
    "required": true
  },{
    "name" : "Value of attribute to update",
    "type":"string",
    "required": true}]
},
{
  "functionName": "URLEncode",
  "link": "https://ampscript.guide/urlencode",
  "description": "This function returns a string in which all non-alphanumeric characters except -, _ and . have been replaced with a percent sign (%) followed by the equivalent hex value. Spaces are encoded as plus (+) signs. The string is encoded in the same way that the posted data from an HTML form is encoded or an application/x-www-form-urlencoded media type. This function is useful when including values as URL parameters in hyperlinks to ensure the string encoding is preserved.",
  "args":[{
    "name":"String containing the URL or value",
    "type":"string",
    "required": true
  },{
    "name" : "Defines whether to convert all non-alphanumeric characters as URL parameters (0) or only as space characters (1). The default value is 0.",
    "type":"number",
    "required": false
  },{
    "name" : "Defines whether to only encode characters in a string prefixed by a URL (0) or all characters (1). The default value is 0.",
    "type":"number",
    "required": false}]
},
{
  "functionName": "V",
  "link": "https://ampscript.guide/v",
  "description": "This function outputs the value of a variable. It is commonly used when a variable is defined within a code block and the result is displayed in an email, message or web page.",
  "args":[{
    "name":"Variable value to output",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "WATP",
  "link": "https://ampscript.guide/watp",
  "description": "This function represents an ordinal placeholder for parameter values configured in the WAT string(s). It can only be set as part of the WAT string configuration by Marketing Cloud Support. Output is included in the result of the WAT function call. See the WAT example below for more context.",
  "args":[{
    "name":"Ordinal value that represents the position of the parameter value in the WAT string",
    "type":"number",
    "required": true
  }]
},
{
  "functionName": "WrapLongURL",
  "link": "https://ampscript.guide/wraplongurl",
  "description": "This function shortens URLs longer than 975 characters to mitigate broken email hyperlinks in Microsoft Outlook, where lengths of URL hyperlinks are limited. The function shortens a URL by substituting the original URL with a shorter platform URL. The platform URL redirects requests to the original URL when requested by the user agent.",
  "args":[{
    "name":"URL to be shortened",
    "type":"string",
    "required": true
  }]
},
{
  "functionName": "AuthenticatedEmployeeID",
  "link": "https://ampscript.guide/authenticatedemployeeid",
  "description": "This function returns the numeric identifier of the authenticated Marketing Cloud account user who is accessing the page. This function will only return the correct value when used in classic landing pages or microsites (a feature available in earlier Enterprise 2.0 accounts). When used in CloudPages, it will return the value related to the user who created the CloudPage, not a value related to the user who is accessing the page from the public URL.",
  "args":[]
},
{
  "functionName": "AuthenticatedEmployeeNotificationAddress",
  "link": "https://ampscript.guide/authenticatedemployeenotificationaddress",
  "description": "This function returns the notification email address of the authenticated Marketing Cloud account user who is accessing the page. This function will only return the correct value when used in classic landing pages or microsites (a feature available in earlier Enterprise 2.0 accounts). When used in CloudPages, it will return the value related to the user who created the CloudPage, not a value related to the user who is accessing the page from the public URL.",
  "args":[]
},
{
  "functionName": "AuthenticatedEmployeeUserName",
  "link": "https://ampscript.guide/authenticatedemployeeusername",
  "description": "This function returns the username of the authenticated Marketing Cloud account user who is accessing the page. This function will only return the correct value when used in classic landing pages or microsites (a feature available in earlier Enterprise 2.0 accounts). When used in CloudPages, it will return the value related to the user who created the CloudPage, not a value related to the user who is accessing the page from the public URL.",
  "args":[]
},
{
  "functionName": "AuthenticatedEnterpriseID",
  "link": "https://ampscript.guide/authenticatedenterpriseid",
  "description": "This function returns the enterprise identifier of Marketing Cloud account (the MID of the Parent Business Unit, or Enterprise ID).",
  "args":[]
},
{
  "functionName": "AuthenticatedMemberID",
  "link": "https://ampscript.guide/authenticatedmemberid",
  "description": "This function returns the MID of the Marketing Cloud account, based on the context of the authenticated user’s session. This function will only return the correct value when used in classic landing pages or microsites (a feature available in earlier Enterprise 2.0 accounts). When used in CloudPages, it will return the MID value of the Business Unit where the page is published, not the MID value based on the context of the authenticated user accessing the published URL.",
  "args":[]
},
{
  "functionName": "AuthenticatedMemberName",
  "link": "https://ampscript.guide/authenticatedmembername",
  "description": "This function returns the name of the Marketing Cloud user’s account, based on the context of their authenticated session. This function will only return the correct value when used in classic landing pages or microsites (a feature available in earlier Enterprise 2.0 accounts). When used in CloudPages, it will only return the value related to the user who created the CloudPage, not a value related to the user who is accessing the page from the public URL.",
  "args":[]
},
{
  "functionName": "DescribeMSCRMEntities",
  "link": "https://ampscript.guide/describemscrmentities",
  "description": "This function returns the logical and display names for all available Microsoft Dynamics CRM entities.",
  "args":[]
},
{
  "functionName": "GUID",
  "link": "https://ampscript.guide/guid",
  "description": "This function returns a 36-character global unique identifier (GUID) string.",
  "args":[]
}
]
