export const knownFunctions = ['Add', 'AddMSCRMListMember', 'AddObjectArrayItem', 'AttachFile', 'AttributeValue',
'AuthenticatedEmployeeID', 'AuthenticatedEmployeeNotificationAddress',
'AuthenticatedEmployeeUserName', 'AuthenticatedEnterpriseID', 'AuthenticatedMemberID',
'AuthenticatedMemberName', 'BarcodeURL', 'Base64Decode', 'Base64Encode', 'BeginImpressionRegion',
'BuildOptionList', 'BuildRowSetFromString', 'BuildRowSetFromXML','BuildRowSetFromJSON', 'Char', 'ClaimRow', 'ClaimRowValue',
'CloudPagesURL', 'Concat', 'ContentArea', 'ContentAreaByName', 'ContentBlockByID', 'ContentBlockByKey',
'ContentBlockByName', 'ContentImageByID', 'ContentImageByKey', 'CreateMSCRMRecord', 'CreateObject',
'CreateSalesforceObject', 'CreateSmsConversation', 'DataExtensionRowCount', 'DateAdd', 'DateDiff',
'DateParse', 'DatePart', 'DecryptSymmetric', 'DeleteData', 'DeleteDE', 'DescribeMSCRMEntities',
'DescribeMSCRMEntityAttributes', 'Divide', 'Domain', 'Empty', 'EncryptSymmetric', 'EndImpressionRegion',
'EndSmsConversation', 'ExecuteFilter', 'ExecuteFilterOrderedRows', 'field', 'Field', 'Format', 'FormatCurrency',
'FormatDate', 'FormatNumber','GetJWT','GetJWTByKeyName', 'GetPortfolioItem', 'GetPublishedSocialContent', 'GetSendTime',
'GetSocialPublishURL', 'GetSocialPublishURLByName', 'GetValue', 'GUID', 'HTTPGet', 'HTTPPost2', 'HTTPPost',
'HTTPRequestHeader', 'IIf', 'Image', 'IndexOf', 'InsertData', 'InsertDE', 'InvokeCreate', 'InvokeDelete',
'InvokeExecute', 'InvokePerform', 'InvokeRetrieve', 'InvokeUpdate', 'IsCHTMLBrowser', 'IsEmailAddress',
'IsNull', 'IsNullDefault', 'IsPhoneNumber', 'Length', 'LiveContentMicrositeURL', 'LocalDateToSystemDate',
'LongSFID', 'Lookup', 'LookupOrderedRows', 'LookupOrderedRowsCS', 'LookupRows', 'LookupRowsCS',
'Lowercase', 'MD5', 'MicrositeURL', 'Mod', 'Multiply', 'Now', 'Output', 'OutputLine', 'ProperCase', 'QueryParameter',
'RaiseError', 'Random', 'RatingStars', 'Redirect', 'RedirectTo', 'RegExMatch', 'Replace', 'ReplaceList',
'RequestParameter', 'RetrieveMSCRMRecords', 'RetrieveMSCRMRecordsFetchXML',
'RetrieveSalesforceJobSources', 'RetrieveSalesforceObjects', 'Row', 'row', 'RowCount', 'SetObjectProperty',
'SetSmsConversationNextKeyword', 'SetStateMSCRMRecord', 'SetValue', 'SHA1', 'SHA256', 'SHA512',
'StringToDate', 'StringToHex', 'Substring', 'Subtract', 'SystemDateToLocalDate', 'TransformXML',
'TreatAsContent', 'TreatAsContentArea', 'Trim', 'UpdateData', 'UpdateDE', 'UpdateMSCRMRecords',
'UpdateSingleSalesforceObject', 'Uppercase', 'UpsertContact', 'UpsertData', 'UpsertDE',
'UpsertMSCRMRecord', 'URLEncode', 'V', 'WAT', 'WATP', 'WrapLongURL', 'v', 'Write', 'Stringify', 'Platform.Load','catch','}catch','try','}try']

export const personalizationStrings = ['__AdditionalEmailAttribute1', '__AdditionalEmailAttribute2', 
'__AdditionalEmailAttribute3', '__AdditionalEmailAttribute4', '__AdditionalEmailAttribute5', 
'_DataSourceName', '_emailid', '_ImpressionRegionID', '_ImpressionRegionName', '_IsTestSend', 
'_JobSubscriberBatchID', '_listname', '_messagecontext', '_MessageTypePreference', '_messagetypepreference', 
'_PreHeader', '_replycontent', '_subscriberkey', 'AdditionalInfo_', 'comment', 'comment_', 'double_opt_in_url', 
'emailaddr', 'emailname_', 'firstname', 'firstname_', 'ftaf_url', 'fullname', 'fullname_', 'jobid', 'lastname', 
'lastname_', 'linkname', 'list_', 'listid', 'listsubid', 'member_addr', 'member_busname', 'member_city', 
'member_country', 'member_postalcode', 'member_state', 'memberid', 'microsite_base_url', 'mobile_number', 
'profile_center_url', 'replyemailaddress', 'replyname', 'short_code', 'subscriberid', 'subscription_center_url', 
'unsub_center_url', 'view_email_url', 'xtday', 'xtdayofweek', 'xtlongdate', 'xtmonth', 'xtmonthnumeric', 'xtshortdate', 'xtyear']